import { httpClient } from './httpClient.js';

const
    LISTPROMO = '/dashboard/promos',
    CREATEPROMO = 'dashboard/promos',
    GETPROMO = 'dashboard/promos/',
    UPDATEPROMO = "dashboard/promos/",
    DELETEPROMO = "dashboard/promos/",
    ACTIVATEPROMO = "dashboard/promos/activate/",
    SEARCHPROMO = "dashboard/promos/search/";

const   
    listPromo = () => httpClient.dashboardService.get(LISTPROMO),
    createPromo = (promo) => httpClient.dashboardService.post(CREATEPROMO, promo),
    getPromo = (id) => httpClient.dashboardService.get(GETPROMO + id),
    updatePromo = (promo, id)  => httpClient.dashboardService.patch(UPDATEPROMO + id, promo),
    deletePromo = (id) => httpClient.dashboardService.delete(DELETEPROMO + id),
    activatePromo = (id) => httpClient.dashboardService.put(ACTIVATEPROMO + id),
    searchPromo = (label) => httpClient.dashboardService.get(SEARCHPROMO + label);

export {
    listPromo,
    createPromo,
    getPromo,
    updatePromo,
    deletePromo,
    activatePromo,
    searchPromo,
}