import Promo from '../promo'
export default class PromoBuilder {
    constructor(type = "", label = "", currency = "", promo_details = {}, discount_type = "", discount = "", min_order_amount = "", max_discount_amount = "", starts_at = "", ends_at = "", promo_type = "", description = "", sub_type = "", limit_type = "", limit_value = "", price_deduction_type = "") {
        this.type = type;
        this.label = label;
        this.currency = currency;
        this.promo_details = promo_details;
        this.discount_type = discount_type;
        this.discount = discount;
        this.min_order_amount = min_order_amount;
        this.max_discount_amount = max_discount_amount;
        this.starts_at = starts_at;
        this.ends_at = ends_at;
        this.promo_type = promo_type;
        this.description = description;
        this.sub_type = sub_type;
        this.limit_type = limit_type;
        this.limit_value = limit_value;
        this.price_deduction_type = price_deduction_type;
    }

    build() {
        return new Promo(this);
    }
}