import { httpClient } from './httpClient.js';

const
    LISTPAYMETHODS = 'dashboard/merchants/payment_methods',
    UPDATEPAYMETHODS = 'dashboard/merchants/payment_methods';

const listPaymentMethods = () => httpClient.dashboardService.get(LISTPAYMETHODS);
const updatePaymentMethods = (methods) => httpClient.dashboardService.post(UPDATEPAYMETHODS, methods);

export {
    listPaymentMethods,
    updatePaymentMethods
};
