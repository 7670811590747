export default class Webhook {
    constructor(builder) {
        this.type = builder.type;
        this.label = builder.label;
        this.currency = builder.currency;
        this.promo_details = builder.promo_details;
        this.discount_type = builder.discount_type;
        this.discount = builder.discount;
        this.min_order_amount = builder.min_order_amount;
        this.max_discount_amount = builder.max_discount_amount;
        this.starts_at = builder.starts_at;
        this.ends_at = builder.ends_at;
        this.promo_type = builder.promo_type;
        this.description = builder.description;
        this.sub_type = builder.sub_type;
        this.limit_type = builder.limit_type;
        this.limit_value = builder.limit_value;
        this.price_deduction_type = builder.price_deduction_type;
    }
}
